/*NAVIGATION*/
#leaderboardNavigation {
    margin-top:1vh;
    margin-left:5%;
    margin-right:5%;
    width:90%;
    display:flex;
    justify-content: space-evenly;
    height:4vh;
}
.navButtonSpacer{
    min-width:15%;
}
.navbutton {
   width:100%;
   height:4vh;
}
.navTexts {
    width:70%;
    /*font-weight: 100;*/
    font-size: medium;
    line-height: 1;
}

/*Listing of albums*/

#levyArvioLeaderboard {
    width: 100%;
    margin:auto;
    margin-top:4vh;
    min-height: 30vh;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap:10px;
    box-shadow:2px 2px 10px #cac6c3;
    background-color:rgba(18, 40, 236, 0.062);
    margin-bottom:4vh;
}

.blrlbHeaderFooter {
    font-size:large;
    text-align: center;
    padding-top:10px;
    padding-bottom:10px;
    box-shadow:2px 2px 10px #cac6c3;
    background-color:rgba(252, 252, 252, 0.4);
}


.blrlb-Item {
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding:15px;
    min-height:60px;
    font-size: small;
    text-align:left;
    margin-left:10px;
    margin-right:10px;
    box-shadow:2px 2px 10px #cac6c3;
    background-color:rgba(252, 252, 252, 0.801);
}

.blrlb-Item img {
    display:block;
    width:10%;
    object-fit:contain;
}

.blrlb-artandNo {
    display:flex;
    width:20%;
    flex-flow: row nowrap;
    text-align:center;
    padding-left:10px;
    padding-right:10px;
}

.blrlb-artandNo img {
    width:100%;
}
.blrlb-orderNo {
    padding-left:10px;
    font-size:large;
    font-weight: bold;
}
.blrlb-item-databox {
    width:100%;
    display:flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    font-size:large;
    line-height: 1;
    padding-left:10px;
    padding-right:10px;
}
.blrlb-titlebox {
    width:100%;
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.ratingContainer {
    display:flex;
    flex-flow: column nowrap;
    max-width:10%;
    gap:20px;
    font-size: small;
    text-align: right;
}

.ratingperc {
    display:flex;
    flex-flow: row nowrap;
    justify-content:center;

}
.ratingDigit {
    display:flex;
}
.ratingDigit img {
    width:100%;
}

.blrlb-ratingPic {
    display: flex;
    flex-flow: row nowrap;
    max-width:10%;
    text-align:center;
    font-size:small;
}


.blrlb-ratingPic img {
    display:block;
    width:70%;
    margin-left:auto;
    margin-right:auto;
    padding-bottom:10px;
    object-fit: contain;

}
.blrlbCopyright {
    font-size:small;
    margin-top:auto;
}

.blrlbRatingBox {
    width:10%;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
    font-size:normal;
}

.blrlbRatingBox img {
    width:20%;
    display:block;
    margin:auto;
    margin-bottom:20px;

}
.blrlbrn {
    /*line-height: 1;*/
    text-align:center;
    /*font-size:normal;*/
}

@media screen and (max-width:930px) {
    .navbutton {
        font-size:x-small;
     }
    .navTexts {
        font-size:small;
    }
    #levyArvioLeaderboard {
        gap:0px;
    }
    .blrlb-Item {
        margin:5px;
        padding:10px;
    }
    .blrlb-artandNo {
        flex-flow: column nowrap;
    }

    .ratingContainer {
        display:flex;
        flex-flow: column nowrap;
        max-width:20%;
        gap:20px;
        font-size: xx-small;
        text-align: right;
    }
    
    .ratingperc {
        display:flex;
        flex-flow: row nowrap;
        justify-content:center;
    
    }
    .ratingDigit {
        display:flex;
    }
    .ratingDigit img {
        width:100%;
    }

    .blrlb-orderNo {
        padding-top:10px;
        padding-left:0px;
        font-size:small;
        font-weight: normal;
    }
    .blrlb-item-databox { 
        font-size: small;
    }
    .blrlbrn {
        font-size:x-small;
    }
    .blrlbCopyright {
        font-size:xx-small;
    }
    .blrlb-ratingPic {
        font-size:xx-small;
    }
}


/*Docs*/
.sidePic {
    margin:4vh;;
    width:20%;
    float:right;
    text-align:center;
}
.sidePic img {
    width:100%;
}
.sidePic i {
    font-size: small;
}
.docsImg {
    width:90%;
    display:flex;
    margin:auto;
}

.codeSnippet {
    font-weight: 100;
    width:90%;
    padding:10px;
    margin:auto;
    color:white;
    margin-top:40px;
    margin-bottom:40px;
    box-shadow:2px 2px 10px #cac6c3;
    background-color:rgba(54,57,62, 1);
}
.codeSnippetalert{
    color:rgb(247, 105, 105);
    font-weight: 800;
}
.codeSnippetNote {
    margin-top:1vh;
    color:rgb(132, 189, 235);
    font-size:small;

}