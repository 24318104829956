.App {
  --leveys: 1000px; /*Laatikot, header, footer*/
  --leveysMobile: 90vw;
  --taustavari: linear-gradient(0deg, rgba(221,221,221,1) 0%, rgba(255,255,255,1) 100%, rgba(0,212,255,1) 100%);

  --reunat: 2vh;
  --varjo: 0px 0px 80px #080808;
  --pienivarjo: 0px 0px 30px rgba(0,0,0,0.05);
  --rakovarjo: 0px 65px #08080886;
  --rako: 16px;
  --rakoMobile: 10px;
  --reunapyor: 5px;

  --marginaali-yla: 20px;
  --marginaali-ylaMobile: 0px;
  --marginaali-reunat: 40px;
  --marginaali-reunatMobile:10px;
  --marginaali-ala: 60px;
  --marginaali-header:30px;
  --marginaali-footer:10px;

  /*Teksti ja linkit*/
  --fonttikoko: 16px;
  --fonttikokoMobile: 10px;
  --fontti-kork:1.4;
  --kirjasin: courier;
  --linkColor :#1b0807;
  --linkHoverColor: #0b64b8;
  text-align: left;

  /*Kuvat*/
  --postListImg: calc(var(--leveys)*0.15);

      /*TAUSTAKUVA*/
      
      /*Flex*/  
      display:flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      min-height:96vh;
      
      border-width:1px;

}

/*Muutokset mobiililaitteen CSS:ään*/
@media screen and (max-width:930px) {
  .App {
   --fonttikoko: var(--fonttikokoMobile);
   --leveys: var(--leveysMobile);
   --rako: var(--rakoMobile);
   --marginaali-yla: var(--marginaali-ylaMobile);
   --marginaali-reunat: var(--marginaali-reunatMobile);
  }
}

/*KUVAT*/
/*.App img {
  object-fit: contain;
}*/

/*VIDEOT YM IFRAME*/
.App iframe {
  display:block;
  margin:auto;
  width: var(--leveys);
  height: calc(var(--leveys)/1.78); /*19:8 aspect ratio*/
}

.BandCampPlayer iframe {
  width: calc(var(--leveys)*0.35);
  height: calc(var(--leveys)*0.47); 
}
.SoundCloudPlayer iframe {
  width: calc(var(--leveys)*0.35);
  height: calc(var(--leveys)*0.2); 
}
.MixCloudPlayer iframe {
  width: calc(var(--leveys)*0.9);
  height: calc(var(--leveys)*0.2);
  margin-bottom: 20px; 
}
.VerticalVideo iframe {
  width: calc(var(--leveys)*0.35);
  height: calc(var(--leveys)*0.63); 
}

@media screen and (max-width:930px) {
  .BandCampPlayer iframe {
    width: calc(var(--leveys)*0.8);
    height: calc(var(--leveys)*1.27);  
  }
  .SoundCloudPlayer iframe {
    width: calc(var(--leveys)*0.9);
    height: calc(var(--leveys)*0.5); 
  }
  .MixCloudPlayer iframe {
    width: calc(var(--leveys)*0.9);
    height: calc(var(--leveys)*0.2); 
  }
  .VerticalVideo iframe {
    width: calc(var(--leveys));
    height: calc(var(--leveys)*1.77); 
  }
}

/*Sivumuotoilu*/
.DualFlexHoriz {
  display:flex;
  flex-flow: row nowrap;
  gap:calc(var(--leveys)*0.02)
}
.DualFlexHorizAlt {
  display:flex;
  flex-direction:row;
  flex-wrap: nowrap;
  gap:calc(var(--leveys)*0.02)
}

.KontentBoxSubNav {
  display:flex;
  flex-flow:row wrap;
  gap: 0px 20px;
  margin-top:10px;
  margin-bottom:0px;
}
.KontentBoxSubNav h1 {
  line-height: 0;
}

.KBSubNavActive {color: #641410;text-decoration: underline;}
.KBSubNav {color: #1b0807;}



@media screen and (max-width:930px) {
  .DualFlexHoriz {
    flex-direction:column-reverse;
    gap:calc(var(--leveys)*0.1);
  }
  .DualFlexHorizAlt {
    flex-direction:column;
    gap:calc(var(--leveys)*0.1);
  }
  .Prose {
    padding:5px;
  }
}


/*Yleiset luokat*/
.lyricQuote {
  font-family:monospace;
  line-height: 1;
  font-size:normal;
  color:#7c6c6c;
  margin-left:2vw;
  margin-right:4vw;
  margin-top:3vh;
  margin-bottom:3vh;
}
.quoteBox {
  padding:40px;
  color: #5e4d4d;
}

.quoteInfoBox {
  text-align:right;
  font-size:normal;
  margin-top:1vh;
  color:#1b0807;
}
.smallList {
  font-size: 12px;
}



.Prose-ImgDiv {
  display:flex;
  flex-flow:column nowrap;
  width:100%;
  margin-top: 60px;
  margin-bottom: 40px;
  object-fit: contain;
}
.Prose-ImgDiv iframe {
  width:100%;
}
.Prose-ImgDiv img {
  max-width:100%;
  width:100%;
  object-fit: contain;
}

.kuvateksti {
  text-align:center;
  font-size:medium;
  color:#7c6c6c;
  margin-top:20px;
}

.imageZoomer {
  display:block;
  width:fit-content;
  margin:auto;

}

.imageZoomer img {
  display:block;
  width:70%;
  cursor:pointer;
  transition:0.3s;
  margin:auto;
}
.imageZoomer img:hover {
  width:100%;
}




/*Asetetaan ylhäällä asetetut muuttujat tänne*/
/*Asetetaan ylhäällä asetetut muuttujat tänne*/
/*Asetetaan ylhäällä asetetut muuttujat tänne*/

.App header {
  width: var(--leveys);
  box-shadow: var(--rakovarjo);
  background: var(--taustavari);
  padding: var(--marginaali-reunat);
  padding-bottom: var(--marginaali-header);
  padding-top: var(--marginaali-header);
  border-radius: var(--reunapyor);
}
.KontentBox {
  width: var(--leveys);
  box-shadow: var(--rakovarjo);
  background: var(--taustavari);
  padding: var(--marginaali-reunat);
  padding-bottom: var(--marginaali-ala);
  padding-top: var(--marginaali-yla);
  border-radius: var(--reunapyor);
}
.App footer {
  width: var(--leveys);
  background: var(--taustavari);
  padding: var(--marginaali-reunat);
  padding-top: var(--marginaali-footer);
  padding-bottom: var(--marginaali-footer);
  border-radius: var(--reunapyor);
}

/*Reunat taustakuvan ja valkoisten laatikoiden välillä*/
.App {padding-top: var(--reunat);padding-bottom: var(--reunat)}

/*Varjo ja raot*/
.MainContainer {
  display:flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  gap: var(--rako);
  box-shadow: var(--varjo);
}


.App {
  font-size: var(--fonttikoko);
  font-family: var(--kirjasin);
  line-height: var(--fontti-kork);
}
.App a {color: var(--linkColor);text-decoration: none;}
.App a:hover {color: var(--linkHoverColor);}

.Prose {
  padding:140px;
  padding-top:0px;
}
.Prose a {
  color :#0b64b8;
  text-decoration: underline;
}

@media screen and (max-width:930px) {
  .Prose {
    padding:5px;
    font-size:small;
  }
  .kuvateksti {
    font-size:small;
  }
}