/*SKRIIM HAHAA*/

.skriimi {
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

    /*background-color: blue;*/
}
.skriimiBanneri {
    width:50%;
    margin:auto;
    margin-bottom:4vh;
}
.skriimiBanneri img {
    width:100%;
}
.etusivuShowMore {
    display:flex;
    font-family: 'Courier New', Courier, monospace;
    cursor:pointer;
    border:none;
    background-color: #ffffff00;
    margin-left:auto;
    padding:0;
    padding-top: 15px;
    font-weight: 100;
  }
.etusivuShowMore :hover {
    color:#0b64b8;
  }
  
/*Viimeisimät postaukset -list*/