.YhteysIcon {
    display:block;
    width: 80px;
    margin-top:10px;
    margin-bottom:10px;
   }
  
   .yhteys {
    display:flex;
    flex-flow: row nowrap;
    gap: 30px;
   }
   .yhteys p {
    margin:10px;
   }

@media screen and (max-width:930px) {
    .YhteysIcon {
    display:block;
    width: 40px;
    margin-left:10px;
    margin-top:10px;
    margin-bottom:10px;
   }
  
   .yhteys {
    display:flex;
    flex-flow: row nowrap;
    gap: 10px;
   }
   .yhteys p {
    margin:10px;
   }
  }





   .cv {
    /*font-family:'Times New Roman', Times, serif;*/ 
    margin-left:auto;
    margin-right:auto;
    background-color:rgba(18, 40, 236, 0.02);
    padding:40px;
    padding-left:40px;
    margin-bottom:4vh;
    box-shadow:2px 2px 10px #cac6c3;
    font-size:normal;
    padding-bottom:40px;
}
.cv a {
    color :#0b64b8;
    text-decoration: underline;
}

.cvSection {
    margin-left:10px;
}

.KouluItem {
    display:flex;
    flex-direction: row;
}
.kouluyr {
    min-width:15%;
}
.cv b {
    font-weight: bold;
}
.kielitaitobox {
    display: flex;
    flex-flow: row wrap;
    margin-right:50%;
}
.kielitaitoItem {
    min-width:50%;
}
.skillsSection {
    display: flex;
    flex-flow: row nowrap;
}
.skillCat {
    min-width:25%;
    font-weight:bold;
}

.cv h4 {
    margin-top:4vh;
}

@media screen and (max-width:930px) {
    .cv {
        /*font-family:'Times New Roman', Times, serif;*/ 
        margin-left:0;
        margin-right:0;
        width:60vw;
        background-color:rgba(18, 40, 236, 0.02);
        padding-left:10px;
        margin-bottom:4vh;
        box-shadow:2px 2px 10px #cac6c3;
        font-size:normal;
        padding-bottom:40px;
    }
    .cvSection {
        margin-left:0px;
    }
    .KouluItem {
        margin-bottom:1vh;
    }
    .kouluyr {
        font-size:xx-small;
        margin-right:10px;
    }
    .skillsSection {
        gap:10px;
        margin-bottom:2vh;
    }
    .skillCat {
        font-size:xx-small;
    }
    
}